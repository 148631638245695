const map = document.querySelector("#map");

if (map) {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiZWZmaWNpZW5jZXdlYiIsImEiOiJjbGttZGp0NGcwNHlzM29xemE2bjdhcTJ3In0.gkZgSiq3i_l_H2HmbWXM7Q";
  const a = map.dataset.latitude,
    e = map.dataset.longitude,
    o = new mapboxgl.Map({
      container: map,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-2.29584, 47.67771],
      zoom: 14,
    });
  new mapboxgl.Marker({ color: "#6b1b69" }).setLngLat([e, a]).addTo(o);
  o.addControl(new mapboxgl.NavigationControl());
}
